import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CollecteResource, CollecteTourneeResource } from "types/caisse.type";
import { FilterProps, ListPaginated } from "types/list.type";

type caisseState = {
  collectes: ListPaginated<CollecteResource>;
  collecteFilter: FilterProps;

  tournees: ListPaginated<CollecteTourneeResource>;
  tourneeFilter: FilterProps;
};

const initialState: caisseState = {
  collectes: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  collecteFilter: {
    page: 1,
    perpage: 10,
  },

  tournees: {
    data: [],
    total: 0,
    totalPage: 0,
    page: 1,
    totalInDatabase: 0,
  },
  tourneeFilter: {
    page: 1,
    perpage: 10,
  },
};

const collecteSlice = createSlice({
  name: "collecte",
  initialState,
  reducers: {
    setCollectes(
      state,
      action: PayloadAction<ListPaginated<CollecteResource>>
    ) {
      state.collectes = action.payload;
    },
    setCollecteFilter(state, { payload }: PayloadAction<Record<string, any>>) {
      state.collecteFilter = payload;
    },

    setCollectesTournees(
      state,
      action: PayloadAction<ListPaginated<CollecteTourneeResource>>
    ) {
      state.tournees = action.payload;
    },
    setCollecteTourneeFilter(
      state,
      { payload }: PayloadAction<Record<string, any>>
    ) {
      state.tourneeFilter = payload;
    },
  },
});

export default collecteSlice.reducer;

export const {
  setCollecteFilter,
  setCollectes,
  setCollectesTournees,
  setCollecteTourneeFilter,
} = collecteSlice.actions;

const getCaisseReducer = (state: any): caisseState => state.caisse;

export const selectCollectes = createSelector(
  getCaisseReducer,
  ({ collectes }) => collectes
);

export const selectCollecteFilter = createSelector(
  getCaisseReducer,
  ({ collecteFilter }) => collecteFilter
);

export const selectCollectesTournees = createSelector(
  getCaisseReducer,
  ({ tournees }) => tournees
);

export const selectCollecteTourneeFilter = createSelector(
  getCaisseReducer,
  ({ tourneeFilter }) => tourneeFilter
);
