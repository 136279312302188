import { Alert, Typography } from "@mui/material";
import { Loadable } from "components/Loadable";
import { MainLayout } from "layout";
import { AuthLogin } from "pages";
import { ForgetPassword } from "pages/auth/forgetpassword";
import { InitPassword } from "pages/auth/initpassword";
import { HomePage } from "pages/home/HomePage";
import ListArticle from "pages/utilitaires/article/ListArticle";
import ListCentre from "pages/utilitaires/centre/ListCentre";
import ListCentreRegional from "pages/utilitaires/centreregional/ListCentreRegional";
import ListDevise from "pages/utilitaires/devise/ListDevise";
import SettingFacture from "pages/utilitaires/facture/SettingFacture";
import ListFamilleArticle from "pages/utilitaires/familleArticle/ListFamilleArticle";
import ListFerie from "pages/utilitaires/feries/ListFerie";
import ListFonction from "pages/utilitaires/fonction/ListFonction";
import ListFournisseur from "pages/utilitaires/fournisseur/ListFournisseur";
import ListMagasins from "pages/utilitaires/magasin/ListMagasin";
import ListPays from "pages/utilitaires/pays/ListPays";
import ListPrestation from "pages/utilitaires/prestations/ListPrestation";
import ListSecteurActivite from "pages/utilitaires/secteuractivite/ListSecteurActivite";
import ListService from "pages/utilitaires/service/ListService";
import ListSituationGeographique from "pages/utilitaires/situationGeographique/ListSituationGeographique";
import ListVille from "pages/utilitaires/villes/ListVille";
import ListZone from "pages/utilitaires/zone/ListZone";
import { lazy } from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { selectUser } from "reducers";
import { LINKS } from "utils/constant";
import { isClient } from "utils/user.helpers";
import { ActiviteNavigation } from "./ActiviteNavigation";
import { LieuNavigation } from "./LieuNavigation";
import { BilleterieNavigation } from "./BilleterieNavigation";
import { ReportingNavigation } from "./ReportingNavigation";
import { UStocksNavigation } from "./UStocksNavigation";
import SettingGlobal from "pages/utilitaires/global/SettingGlobal";
import { SsbNavigation } from "./SsbNavigation";
import ListPiece from "pages/utilitaires/billetsetpieces/ListPiece";
import ListBillet from "pages/utilitaires/billetsetpieces/ListBillet";

// Personnels
const ListPersonnel = Loadable(
  lazy(() => import("pages/personnels/ListPersonnel"))
);
const CreatePersonnel = Loadable(
  lazy(() => import("pages/personnels/CreatePersonnel"))
);
const ViewPersonnel = Loadable(
  lazy(() => import("pages/personnels/ViewPersonnel"))
);
const EditPersonnel = Loadable(
  lazy(() => import("pages/personnels/EditPersonnel"))
);

// Clients
const ListClient = Loadable(lazy(() => import("pages/clients/ListClient")));
const CreateClient = Loadable(lazy(() => import("pages/clients/CreateClient")));
const ViewClient = Loadable(lazy(() => import("pages/clients/ViewClient")));
const EditClient = Loadable(lazy(() => import("pages/clients/EditClient")));
const FicheClient = Loadable(lazy(() => import("pages/clients/FicheClient")));

// Sites
const ListSite = Loadable(lazy(() => import("pages/sites/ListSite")));
const CreateSite = Loadable(lazy(() => import("pages/sites/CreateSite")));
const EditSite = Loadable(lazy(() => import("pages/sites/EditSite")));
const ViewSite = Loadable(lazy(() => import("pages/sites/ViewSite")));

// Vehicules
const ListVehicule = Loadable(
  lazy(() => import("pages/vehicules/ListVehicule"))
);
const ViewVehicule = Loadable(
  lazy(() => import("pages/vehicules/ViewVehicule"))
);
const CreateVehicule = Loadable(
  lazy(() => import("pages/vehicules/CreateVehicule"))
);
const EditVehicule = Loadable(
  lazy(() => import("pages/vehicules/EditVehicule"))
);

// Dessertes
const ListDesserte = Loadable(
  lazy(() => import("pages/dessertes/ListDesserte"))
);
const CreateDesserte = Loadable(
  lazy(() => import("pages/dessertes/CreateDesserte"))
);
const ViewDesserte = Loadable(
  lazy(() => import("pages/dessertes/ViewDesserte"))
);

//Tournees
const ListTournee = Loadable(lazy(() => import("pages/tournees/ListTournee")));
const CreateTournee = Loadable(
  lazy(() => import("pages/tournees/CreateTournee"))
);
const ViewTournee = Loadable(lazy(() => import("pages/tournees/ViewTournee")));

//Reporting
const AnalyseOperationnelle = Loadable(
  lazy(() => import("pages/transport/reporting/AnalyseOperationnelle"))
);

const AnalyseVehicule = Loadable(
  lazy(() => import("pages/transport/reporting/AnalyseVehicule"))
);

const ChiffreAffaire = Loadable(
  lazy(() => import("pages/transport/reporting/ChiffreAffaire"))
);

// Users
const ListUser = Loadable(
  lazy(() => import("pages/utilitaires/users/ListUser"))
);
const CreateUser = Loadable(
  lazy(() => import("pages/utilitaires/users/CreateUser"))
);
const ViewUser = Loadable(
  lazy(() => import("pages/utilitaires/users/ViewUser"))
);
// Roles
const ListRole = Loadable(
  lazy(() => import("pages/utilitaires/roles/ListRole"))
);
const ViewRole = Loadable(
  lazy(() => import("pages/utilitaires/roles/ViewRole"))
);
const CreateRole = Loadable(
  lazy(() => import("pages/utilitaires/roles/CreateRole"))
);
// Facture
const ListFacture = Loadable(lazy(() => import("pages/factures/ListFacture")));

const CreateFacture = Loadable(
  lazy(() => import("pages/factures/CreateFacture"))
);
const ViewFacture = Loadable(lazy(() => import("pages/factures/ViewFacture")));

// Reçu
const ListRecu = Loadable(lazy(() => import("pages/transport/recus/ListRecu")));

const NotificationsTemplate = Loadable(
  lazy(() => import("pages/notifications/NotificationsTemplate"))
);
const ViewNotification = Loadable(
  lazy(() => import("pages/notifications/ViewNotification"))
);

//Incidents
const ListIncident = Loadable(
  lazy(() => import("pages/incidents/ListIncident"))
);
const CreateIncident = Loadable(
  lazy(() => import("pages/incidents/CreateIncident"))
);
const ViewIncident = Loadable(
  lazy(() => import("pages/incidents/ViewIncident"))
);

// Appro
const ListApprovisionnement = Loadable(
  lazy(() => import("pages/stock/appros/ListApprovisionnement"))
);
const CreateApprovisionnement = Loadable(
  lazy(() => import("pages/stock/appros/CreateAppro"))
);
const ViewApprovisionnement = Loadable(
  lazy(() => import("pages/stock/appros/ViewAppro"))
);

// Vente
const ListVente = Loadable(lazy(() => import("pages/stock/ventes/ListVente")));
const CreateVente = Loadable(
  lazy(() => import("pages/stock/ventes/CreateVente"))
);
const ViewVente = Loadable(lazy(() => import("pages/stock/ventes/ViewVente")));

// Inventaire
const ListInventaire = Loadable(
  lazy(() => import("pages/stock/inventaires/ListInventaire"))
);
const CreateInventaire = Loadable(
  lazy(() => import("pages/stock/inventaires/CreateInventaire"))
);
const ViewInventaire = Loadable(
  lazy(() => import("pages/stock/inventaires/ViewInventaire"))
);

//
const ListStockArticle = Loadable(
  lazy(() => import("pages/stock/articles/ListStockArticle"))
);
const ViewStockArticle = Loadable(
  lazy(() => import("pages/stock/articles/ViewStockArticle"))
);

// Magasin
const ListMagasin = Loadable(
  lazy(() => import("pages/stock/magasins/ListMagasin"))
);
const ViewMagasin = Loadable(
  lazy(() => import("pages/stock/magasins/ViewMagasin"))
);

// Paiement sur site
const ListBeneficiaire = Loadable(
  lazy(() => import("pages/paiments-sur-site/beneficiaires/ListBeneficiaire"))
);
const ViewBeneficiaire = Loadable(
  lazy(() => import("pages/paiments-sur-site/beneficiaires/ViewBeneficiaire"))
);

const ListPaiement = Loadable(
  lazy(() => import("pages/paiments-sur-site/paiements/ListPaiement"))
);
const CreatePaiement = Loadable(
  lazy(() => import("pages/paiments-sur-site/paiements/CreatePaiement"))
);
const ViewPaiement = Loadable(
  lazy(() => import("pages/paiments-sur-site/paiements/ViewPaiement"))
);

// SSB setting
const ListCassette = Loadable(
  lazy(() => import("pages/utilitaires/cassette/ListCassette"))
);
const ListTicketType = Loadable(
  lazy(() => import("pages/utilitaires/ticketType/ListTicketType"))
);
const ListTicketCategorie = Loadable(
  lazy(() => import("pages/utilitaires/ticketCategorie/ListTicketCategorie"))
);

// DAB
const ListDab = Loadable(lazy(() => import("pages/ssb/dabs/ListDab")));
const CreateDab = Loadable(lazy(() => import("pages/ssb/dabs/CreateDab")));
const ViewDab = Loadable(lazy(() => import("pages/ssb/dabs/ViewDab")));

// Ticket
const ListTicket = Loadable(lazy(() => import("pages/ssb/tickets/ListTicket")));
const CreateTicket = Loadable(
  lazy(() => import("pages/ssb/tickets/CreateTicket"))
);
const ViewTicket = Loadable(lazy(() => import("pages/ssb/tickets/ViewTicket")));

// Caisses
const ListCollecte = Loadable(
  lazy(() => import("pages/caisses/collectes/ListCollecte"))
);
const CreateCollecte = Loadable(
  lazy(() => import("pages/caisses/collectes/CreateCollecte"))
);
const ViewCollecte = Loadable(
  lazy(() => import("pages/caisses/collectes/ViewCollecte"))
);

const ListCollecteTournee = Loadable(
  lazy(() => import("pages/caisses/tournees/ListCollecteTournee"))
);
const CreateCollecteTournee = Loadable(
  lazy(() => import("pages/caisses/tournees/CreateCollecteTournee"))
);
const ViewCollecteTournee = Loadable(
  lazy(() => import("pages/caisses/tournees/ViewCollecteTournee"))
);

const ListCoffreFort = Loadable(
  lazy(() => import("pages/caisses/coffre-fort/ListCoffreFort"))
);
const ViewCoffreFort = Loadable(
  lazy(() => import("pages/caisses/coffre-fort/ViewCoffreFort"))
);

export const Navigation = () => {
  const user = useSelector(selectUser);

  return (
    <Routes>
      <Route path="/login" element={<AuthLogin />} />
      <Route path="/forgetpassword" element={<ForgetPassword />} />
      <Route path="/initpassword" element={<InitPassword />} />
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<HomePage />} />

        {/* navigation personnels */}
        <Route path="/personnels" element={<Outlet />}>
          <Route index element={<ListPersonnel />} />
          <Route path="create" element={<CreatePersonnel />} />
          <Route path=":id/view" element={<ViewPersonnel />} />
          <Route path=":id/edit" element={<EditPersonnel />} />
        </Route>

        {/* navigation clients */}
        <Route path="/clients" element={<Outlet />}>
          <Route
            index
            element={isClient(user) ? <FicheClient /> : <ListClient />}
          />
          <Route path="create" element={<CreateClient />} />
          <Route path=":id/view" element={<ViewClient />} />
          <Route path=":id/edit" element={<EditClient />} />
          <Route path="fiche" element={<FicheClient />} />
        </Route>

        {/* navigation sites */}
        <Route path="/sites" element={<Outlet />}>
          <Route index element={<ListSite />} />
          <Route path="create" element={<CreateSite />} />
          <Route path=":id/view" element={<ViewSite />} />
          <Route path=":id/edit" element={<EditSite />} />
        </Route>

        {/* navigation vehicules */}
        <Route path={LINKS.vehicule.list} element={<Outlet />}>
          <Route index element={<ListVehicule />} />
          <Route path="create" element={<CreateVehicule />} />
          <Route path=":id/view" element={<ViewVehicule />} />
          <Route path=":id/edit" element={<EditVehicule />} />
        </Route>

        {/* navigation tournees */}
        <Route path={LINKS.tournees.list} element={<Outlet />}>
          <Route index element={<ListTournee />} />
          <Route path="create" element={<CreateTournee />} />
          <Route path=":id/view" element={<ViewTournee />} />
        </Route>

        {/* navigation dessertes */}
        <Route path={LINKS.dessertes.list} element={<Outlet />}>
          <Route index element={<ListDesserte />} />
          <Route path="create" element={<CreateDesserte />} />
          <Route path=":id/view" element={<ViewDesserte />} />
          {/* <Route path=":id/edit" element={<EditCommande />} /> */}
        </Route>

        {/* navigation recu */}
        <Route path={LINKS.recus.list} element={<Outlet />}>
          <Route index element={<ListRecu />} />
        </Route>

        <Route path={LINKS.incidents.list} element={<Outlet />}>
          <Route index element={<ListIncident />} />
          <Route path="create" element={<CreateIncident />} />
          <Route path=":id/view" element={<ViewIncident />} />
        </Route>

        {/* navigation users */}
        <Route path={LINKS.users.list} element={<Outlet />}>
          <Route index element={<ListUser />} />
          <Route path="create" element={<CreateUser />} />
          <Route path=":id/view" element={<ViewUser />} />
        </Route>

        {/* navigation roles */}
        <Route path={LINKS.roles.list} element={<Outlet />}>
          <Route index element={<ListRole />} />
          <Route path=":id/view" element={<ViewRole />} />
          <Route path="create" element={<CreateRole />} />
        </Route>

        <Route path={LINKS.lieux.base} element={<LieuNavigation />}>
          <Route index element={<ListPays />} />
          <Route path={LINKS.lieux.pays} element={<ListPays />} />
          <Route path={LINKS.lieux.villes} element={<ListVille />} />
          <Route
            path={LINKS.lieux.centresRegionaux}
            element={<ListCentreRegional />}
          />
          <Route path={LINKS.lieux.centres} element={<ListCentre />} />
          <Route
            path={LINKS.lieux.situationGeographiques}
            element={<ListSituationGeographique />}
          />
          <Route path={LINKS.lieux.zones} element={<ListZone />} />
          <Route path={LINKS.lieux.devises} element={<ListDevise />} />
        </Route>

        <Route path={LINKS.activites.base} element={<ActiviteNavigation />}>
          <Route index element={<ListPrestation />} />
          <Route
            path={LINKS.activites.secteurActivites}
            element={<ListSecteurActivite />}
          />
          <Route
            path={LINKS.activites.prestations}
            element={<ListPrestation />}
          />
          <Route path={LINKS.activites.services} element={<ListService />} />
          <Route path={LINKS.activites.fonctions} element={<ListFonction />} />
          <Route path={LINKS.activites.joursferies} element={<ListFerie />} />
        </Route>

        <Route path={LINKS.factures.list} element={<Outlet />}>
          <Route index element={<ListFacture />} />
          <Route path={LINKS.factures.create} element={<CreateFacture />} />
          <Route path=":id/view" element={<ViewFacture />} />
        </Route>

        <Route path={LINKS.settingFactures} element={<SettingFacture />} />
        <Route path={LINKS.global} element={<SettingGlobal />} />

        <Route path={LINKS.billeteries.base} element={<BilleterieNavigation />}>
          <Route index element={<ListBillet />} />
          <Route path={LINKS.billeteries.billets} element={<ListBillet />} />
          <Route path={LINKS.billeteries.pieces} element={<ListPiece />} />
        </Route>

        <Route path={LINKS.reporting.base} element={<ReportingNavigation />}>
          <Route index element={<ChiffreAffaire />} />
          <Route path={LINKS.reporting.ca} element={<ChiffreAffaire />} />
          <Route
            path={LINKS.reporting.operationnelle}
            element={<AnalyseOperationnelle />}
          />
          <Route
            path={LINKS.reporting.vehicule}
            element={<AnalyseVehicule />}
          />
        </Route>

        <Route
          path={LINKS.notifications.list}
          element={<NotificationsTemplate />}
        >
          <Route
            index
            element={
              <Typography color="secondary" fontStyle="italic">
                Selectionnez une notification pour voir son contenu
              </Typography>
            }
          />
          <Route path=":id/view" element={<ViewNotification />} />
        </Route>

        <Route path={LINKS.ssb.base} element={<SsbNavigation />}>
          <Route index element={<ListTicketCategorie />} />
          <Route path={LINKS.ssb.cassettes} element={<ListCassette />} />
          <Route path={LINKS.ssb.ticketTypes} element={<ListTicketType />} />
          <Route
            path={LINKS.ssb.ticketCategories}
            element={<ListTicketCategorie />}
          />
        </Route>

        <Route path={LINKS.ustocks.base} element={<UStocksNavigation />}>
          <Route index element={<ListFournisseur />} />
          <Route
            path={LINKS.ustocks.fournisseurs}
            element={<ListFournisseur />}
          />
          <Route path={LINKS.ustocks.magasins} element={<ListMagasins />} />
          <Route
            path={LINKS.ustocks.familleArticles}
            element={<ListFamilleArticle />}
          />
          <Route path={LINKS.ustocks.articles} element={<ListArticle />} />
        </Route>

        {/* navigation approvisionnement */}
        <Route path={LINKS.approvisionnements.list} element={<Outlet />}>
          <Route index element={<ListApprovisionnement />} />
          <Route path="create" element={<CreateApprovisionnement />} />
          <Route path=":id/view" element={<ViewApprovisionnement />} />
        </Route>

        {/* navigation vente */}
        <Route path={LINKS.ventes.list} element={<Outlet />}>
          <Route index element={<ListVente />} />
          <Route path="create" element={<CreateVente />} />
          <Route path=":id/view" element={<ViewVente />} />
        </Route>

        {/* navigation articles stocks */}
        <Route path={LINKS.stocks.list} element={<Outlet />}>
          <Route index element={<ListStockArticle />} />
          <Route
            path={LINKS.stocks.viewArticle}
            element={<ViewStockArticle />}
          />
        </Route>

        {/* navigation magasin */}
        <Route path={LINKS.magasins.list} element={<Outlet />}>
          <Route index element={<ListMagasin />} />
          <Route path=":id/view" element={<ViewMagasin />} />
        </Route>

        {/* navigation inventaire */}
        <Route path={LINKS.inventaires.list} element={<Outlet />}>
          <Route index element={<ListInventaire />} />
          <Route path="create" element={<CreateInventaire />} />
          <Route path=":id/view" element={<ViewInventaire />} />
        </Route>

        {/* navigation paiements */}
        <Route path={LINKS.paiements.list} element={<Outlet />}>
          <Route index element={<ListPaiement />} />
          <Route path="create" element={<CreatePaiement />} />
          <Route path=":id/view" element={<ViewPaiement />} />
        </Route>

        {/* navigation bénéficiaires */}
        <Route path={LINKS.beneficiaires.list} element={<Outlet />}>
          <Route index element={<ListBeneficiaire />} />
          <Route path=":id/view" element={<ViewBeneficiaire />} />
        </Route>

        {/* navigation dab */}
        <Route path={LINKS.dabs.list} element={<Outlet />}>
          <Route index element={<ListDab />} />
          <Route path="create" element={<CreateDab />} />
          <Route path=":id/view" element={<ViewDab />} />
        </Route>

        {/* navigation tickets */}
        <Route path={LINKS.tickets.list} element={<Outlet />}>
          <Route index element={<ListTicket />} />
          <Route path="create" element={<CreateTicket />} />
          <Route path=":id/view" element={<ViewTicket />} />
        </Route>

        {/* navigation collectes */}
        <Route path={LINKS.collectes.list} element={<Outlet />}>
          <Route index element={<ListCollecte />} />
          <Route path="create" element={<CreateCollecte />} />
          <Route path=":id/view" element={<ViewCollecte />} />
        </Route>

        {/* navigation collectes tournees*/}
        <Route path={LINKS.collectesTournees.list} element={<Outlet />}>
          <Route index element={<ListCollecteTournee />} />
          <Route path="create" element={<CreateCollecteTournee />} />
          <Route path=":id/view" element={<ViewCollecteTournee />} />
        </Route>

        {/* navigation coffre fort*/}
        <Route path={LINKS.coffresForts.list} element={<Outlet />}>
          <Route index element={<ListCoffreFort />} />
          <Route path=":id/view" element={<ViewCoffreFort />} />
        </Route>

        {/*         <Route path="ssb-reporting" element={<Outlet />}>
          <Route index element={<SsbReporting />} />
        </Route> */}

        <Route
          path="*"
          element={
            <Alert variant="filled" color="error">
              Cette page n'est pas disponible
            </Alert>
          }
        />
      </Route>
    </Routes>
  );
};
