import { CollecteResource, CollecteTourneeResource } from "types/caisse.type";
import { CrudService } from "./crudService";
import { requestGet, requestPost, requestPut } from "./request";
import { RequestParam } from "types";
import { requestParams } from "utils/helpers";
import { exportService } from "./exportService";

const collecte = new CrudService<CollecteResource, CollecteResource>(
  "collectes"
);

export const collecteService = {
  ...collecte,

  annuler: (collecteId: string) => {
    return requestPut<{ data: CollecteResource }>(
      `${collecte.getBase()}/${collecteId}/annuler`
    );
  },

  generate: (body: any) => {
    return requestPost(`${collecte.getBase()}/generate`, body);
  },

  aPlanifier: (params?: RequestParam) => {
    return requestGet<{ data: CollecteResource[] }>(
      `${collecte.getBase()}/a-planifier${requestParams(params)}`
    );
  },

  reconnaissance: (collecteId: string, body: Record<string, any>) => {
    return requestPut<{ data: CollecteResource }>(
      `${collecte.getBase()}/${collecteId}/reconnaissance`,
      body
    ).then((r) => r.data);
  },

  bordereau: async (collecteId: string) => {
    return await exportService.pdf(
      `${collecte.getBase()}/${collecteId}/bordereau`
    );
  },
};

const collecteTournees = new CrudService<
  CollecteTourneeResource,
  CollecteTourneeResource
>("collectetournees");

export const collecteTourneeService = {
  ...collecteTournees,
  findCollectes: async (tourneeId: string) => {
    const { data } = await requestGet<{ data: CollecteResource[] }>(
      `${collecteTournees.getBase()}/${tourneeId}/collectes`
    );
    return data;
  },
};
