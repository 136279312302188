import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { FC, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { getJsonValue } from "utils/helpers";

export const TextInput: FC<
  {
    name: string;
  } & TextFieldProps
> = ({
  name,
  variant = "outlined",
  fullWidth = true,
  InputProps,
  size = "small",
  ...rest
}) => {
  const {
    formState: { errors },
    watch,
    register,
  } = useFormContext();

  const value = watch(name);

  const shrink = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return undefined;
    }
    return true;
  }, [value]);

  const error = useMemo(() => {
    return getJsonValue(errors, name);
  }, [errors, name]);

  return (
    <TextField
      id={name}
      {...rest}
      {...register(name)}
      variant={variant}
      fullWidth={fullWidth}
      error={!!error}
      size={size}
      helperText={error?.message?.toString() || rest.helperText}
      InputProps={{
        ...(InputProps || {}),
      }}
      InputLabelProps={shrink ? { shrink } : undefined}
    />
  );
};

export const TextInputWithDebounce: FC<
  {
    name: string;
    errors?: Record<string, string>;
    onValueChanged: (value?: string) => void;
  } & TextFieldProps
> = ({
  name,
  variant = "outlined",
  fullWidth = true,
  InputProps,
  size = "small",
  value,
  errors,
  onValueChanged,
  ...rest
}) => {
  const typingTimeOuf = useRef<any>();
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    setDebounceValue(value);
  }, [value]);

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeOuf.current);
    };
  }, []);

  const onChange = (e: any) => {
    const newValue = e.target.value;
    setDebounceValue(newValue);

    if (typingTimeOuf.current) {
      clearTimeout(typingTimeOuf.current);
    }

    typingTimeOuf.current = setTimeout(() => {
      onValueChanged(newValue);
    }, 1000);
  };

  const shrink = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return undefined;
    }
    return true;
  }, [value]);

  const error = useMemo(() => {
    return getJsonValue(errors || {}, name);
  }, [errors, name]);

  return (
    <TextField
      id={name}
      {...rest}
      value={debounceValue}
      variant={variant}
      onChange={onChange}
      fullWidth={fullWidth}
      error={!!error}
      size={size}
      helperText={error?.message?.toString() || rest.helperText}
      InputProps={{
        ...(InputProps || {}),
      }}
      InputLabelProps={shrink ? { shrink } : undefined}
    />
  );
};

export const KilometrageAdornment = (
  <InputAdornment position="end">
    <Typography fontWeight={700} component="span">
      Km
    </Typography>
  </InputAdornment>
);

export const FcaAdornment = (
  <InputAdornment position="end">
    <Typography fontWeight={600} component="span">
      FCFA
    </Typography>
  </InputAdornment>
);

export const FcaKmAdornment = (
  <InputAdornment position="end">
    <Typography fontWeight={700} component="span">
      FCFA / KM
    </Typography>
  </InputAdornment>
);

interface FormatProps {
  name: string;
}

//@ts-ignore
export const MontantFormat = forwardRef<NumberFormat, FormatProps>(
  function NumberFormatCustom(props, ref) {
    const { watch } = useFormContext();
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        value={watch(props.name)}
        thousandSeparator=" "
        isNumericString
        decimalSeparator=","
      />
    );
  }
);

//@ts-ignore
export const OnlyChiffreFormat = forwardRef<NumberFormat, FormatProps>(
  function NumberFormatCustom(props, ref) {
    const { watch } = useFormContext();
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        value={watch(props.name)}
        thousandSeparator=""
        isNumericString
      />
    );
  }
);

//@ts-ignore
export const ThousandFormat = forwardRef<NumberFormat, FormatProps>(
  function NumberFormatCustom(props, ref) {
    const { watch } = useFormContext();
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        value={watch(props.name)}
        thousandSeparator=" "
        isNumericString
        decimalSeparator=","
      />
    );
  }
);
//@ts-ignore
export const ThousandFormatSimple = forwardRef<NumberFormat, FormatProps>(
  function NumberFormatCustom(props, ref) {
    return (
      <NumberFormat
        {...props}
        getInputRef={ref}
        thousandSeparator=" "
        isNumericString
        decimalSeparator=","
      />
    );
  }
);

type MontantFormatTextProps = {
  value?: string | number | null;
};
export const MontantFormatText: FC<MontantFormatTextProps> = ({ value }) => {
  if (value === undefined || value === null) {
    return null;
  }
  return (
    <NumberFormat
      value={value}
      thousandSeparator=" "
      isNumericString
      decimalSeparator=","
      displayType="text"
    />
  );
};

type ThousandTextProps = {
  value?: string | number;
};
export const ThousandText: FC<ThousandTextProps> = ({ value }) => {
  if (value === undefined || value === null) {
    return null;
  }
  return (
    <NumberFormat
      value={value}
      thousandSeparator=" "
      isNumericString
      decimalSeparator=","
      displayType="text"
    />
  );
};
